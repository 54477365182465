import "@fontsource/dm-sans"
import "@fontsource/dm-sans/500.css"
import "@fontsource/dm-sans/700.css"
import "@fontsource/dm-mono"
import "@fontsource/dm-mono/500.css"
import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { Translation, useTranslation } from 'react-i18next';
import { I18nextProvider } from 'react-i18next';
import i18n from './src/helpers/i18n';

const CookieConsentWithTranslation = () => {
    const { t } = useTranslation();

    return (
        <CookieConsent
            location="bottom"
            buttonText={t('cookie.button')}
            cookieName="myAwesomeCookieName2"
            style={{ background: "#FAD829" }}
            buttonStyle={{ color: "#FFF", background: "#2C3878", fontSize: "16px", padding: "10px 20px", borderRadius: "3px" }}
            expires={150}
        >
            <Translation>
                {
                    t => <span style={{ color: "#3C3C3C" }}>{t('cookie.content')}</span>
                }
            </Translation>
            <a href={t('cookie.href')} style={{ color: "#3C3C3C", textDecoration: "none" }}>
                <Translation>
                    {
                        t => <span style={{ paddingLeft: "5px" }}>{t('cookie.link')}</span>
                    }
                </Translation>
            </a>
        </CookieConsent>
    );
};

export const wrapRootElement = ({ element }) => (
    <I18nextProvider i18n={i18n}>
        {element}
        <CookieConsentWithTranslation />
    </I18nextProvider>
);
